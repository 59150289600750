<template>
  <div>
    <b-tabs>
      <b-tab title="Principal">
        <b-row>
          <b-col sm="12">
            <InputText title="Identificação" field="identification" :formName="formName" :required="true"
              :maxLength="50" v-model="document.identification" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <CheckboxGroup title="Em qual módulo será utilizado?" field="module" :initialValue="document.module"
              :formName="formName" :options="options" v-model="document.module" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Produtos" v-if="isVisible(['Rental', 'Operational', 'PDV'])">
        <b-row>
          <b-col sm="3">
            <RadioGroup title="Agrupar" :formName="formName" field="group" :options="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]" v-model="document.config.product.group" />
          </b-col>
          <b-col sm="7">
            <RadioGroup title="Ordenar" :formName="formName" field="order" :options="[
              { text: 'Padrão', value: 'default' },
              { text: 'Categoria', value: 'category' },
              { text: 'Nome do produto', value: 'product' },
            ]" v-model="document.config.product.orderBy" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <CheckboxGroup title="Mostrar" field="permission" :markFormDirty="false" :options="[
              { text: 'Produtos da locação', value: 'showProduct' },
              { text: 'Produtos somente estoque', value: 'showOnlyStock' },
              { text: 'Acessório(s)', value: 'showAccessory' },
              { text: 'Serviço(s)', value: 'showService' },
            ]" :initialValue="document.config.product.show" v-model="document.config.product.show" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Movimentação" v-if="isVisible(['MovementStock'])">
        <b-row>
          <b-col sm="7">
            <RadioGroup title="Ordenar" :formName="formName" field="order" :options="[
              { text: 'Padrão', value: 'default' },
              { text: 'Categoria', value: 'category' },
              { text: 'Nome do produto', value: 'product' },
            ]" v-model="document.config.moviment.orderBy" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Marca d'água">
        <div class="div-alert">
          <Alert type="warning">
            <span>
              Atenção, <b>NÃO</b> funciona na opção Imprimir!
            </span>
          </Alert>
        </div>
        <FileUpload title="Carregar Imagem" classIcon="fa-solid fa-file-export" container="nixloc-watermark"
          accepted=".png .jpg" allowed=".png .jpg" :disabled="true" urlPost="/api/v1/adm/file-upload/upload"
          urlRemove="/api/v1/adm/file-upload/delete" :nameDataBase="document.imageWatermark"
          v-model="document.imageWatermark" />
        <br />
        <b-row>
          <b-col sm="3">
            <InputNumber title="Altura (pixel)" field="imageHeight" :formName="formName"
              v-model="document.imageHeight" />
          </b-col>
          <b-col sm="3">
            <InputNumber title="Largura (pixel)" field="imageWidth" :formName="formName"
              v-model="document.imageWidth" />
          </b-col>
          <b-col sm="3">
            <InputNumber title="Nítidez (%)" field="opacity" :formName="formName" v-model="document.opacity" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <RadioGroup title="Alinhamento Vertical" :formName="formName" field="order" :options="[
              { text: 'Topo', value: 0 },
              { text: 'Centro', value: 1 },
              { text: 'Rodapé', value: 2 },
            ]" v-model="document.verticalAlignment" />
          </b-col>
          <b-col sm="6">
            <RadioGroup title="Alinhamento Horizontal" :formName="formName" field="order" :options="[
              { text: 'Esquerda', value: 0 },
              { text: 'Centro', value: 1 },
              { text: 'Direita', value: 2 },
            ]" v-model="document.horizontalAlignment" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Margem">
        <Alert type="warning">
          <span>
            Atenção, <b>NÃO</b> funciona na opção Imprimir!
          </span>
        </Alert>
        <b-row>
          <b-col sm="3">
            <InputNumber title="Topo" field="imageHeight" :formName="formName" v-model="document.marginTop" />
          </b-col>
          <b-col sm="3">
            <InputNumber title="Esquerda" field="imageWidth" :formName="formName" v-model="document.marginLeft" />
          </b-col>
          <b-col sm="3">
            <InputNumber title="Direita" field="imageHeight" :formName="formName" v-model="document.marginRight" />
          </b-col>
          <b-col sm="3">
            <InputNumber title="Rodapé" field="imageWidth" :formName="formName" v-model="document.marginBottom" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Outros">
        <InputText title="Style Css" field="css" :formName="formName" :required="false" v-model="document.styleCss" />
        <RadioGroup title="Mostrar Páginas (ex: 1 de 5)" :formName="formName" field="order" :options="[
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ]" v-model="document.showPages" />
      </b-tab>
    </b-tabs>
    <br />
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="create" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-create" size="medium" :clicked="create" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import FileUpload from "@nixweb/nixloc-ui/src/component/forms/FileUpload";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import Document from "@/components/modules/adm/document/Document.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "DocumentCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText, FileUpload, InputNumber, Alert, RadioGroup, CheckboxGroup },
  data() {
    return {
      id: this.$route.params.id,
      formName: "documentCreateUpdate",
      document: new Document(),
      urlCreate: "/api/v1/adm/document/create",
      urlUpdate: "/api/v1/adm/document/update",
    };
  },
  computed: {
    ...mapState("generic", ["documentHtml"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasModule"]),
    options() {
      let options = [];

      let billing = { text: "Faturamento", value: "Billing" };
      let finance = { text: "Financeiro", value: "Finance" };
      let rental = { text: "Locação", value: "Rental" };
      let maintenance = { text: "Manutenção", value: "Maintenance" };
      let movementStock = { text: "Movimentação", value: "MovementStock" };
      let operational = { text: "Operacional", value: "Operational" };

      if (this.hasModule("Billing")) options.push(billing);
      if (this.hasModule("Finance")) options.push(finance);
      if (this.hasModule("Rental")) options.push(rental);
      if (this.hasModule("Maintenance")) options.push(maintenance);
      if (this.hasModule("Rental")) options.push(movementStock);
      if (this.hasModule("Operational")) options.push(operational);

      return options;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeEvent",
      "removeLoading",
      "openModal",
      "hideModal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    create() {
      this.document.documentHtml = this.documentHtml;
      if (this.document.id) {
        let params = { url: this.urlUpdate, obj: this.document };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.addEvent({ name: "documentEditorModified" });
          this.removeLoading(["create", "saveDocument"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.document };
        this.postApi(params).then((response) => {
          if (response.success) {
            this.$router.push({
              name: "documentUpdate",
              params: { id: response.content.id },
            });
            this.hideModal();
          }
          this.removeLoading(["create"]);
        });
      }
    },
    update(data) {
      this.document = new Document();
      this.document.update(data);
      this.$emit("input", data.module);
      this.removeLoading([data.id]);
    },
    isVisible(module) {
      var ret = false;
      module.forEach((name) => {
        var has = this.document.module.includes(name);
        if (has) ret = true;
      });

      return ret;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "createDocument") {
          this.resetValidation(this.formName);
          this.document = new Document();
        }
        if (event.name == "updateDocument") this.update(event.data);
        if (event.name == "saveDocument") this.create();
        if (event.name == "saveAs") {
          this.resetValidation(this.formName);
          this.document = new Document();
          this.openModal("saveDocument");
          this.removeLoading(["saveAs"]);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-alert {
  margin-bottom: 15px;
}
</style>