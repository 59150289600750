var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Principal"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('InputText',{attrs:{"title":"Identificação","field":"identification","formName":_vm.formName,"required":true,"maxLength":50},model:{value:(_vm.document.identification),callback:function ($$v) {_vm.$set(_vm.document, "identification", $$v)},expression:"document.identification"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('CheckboxGroup',{attrs:{"title":"Em qual módulo será utilizado?","field":"module","initialValue":_vm.document.module,"formName":_vm.formName,"options":_vm.options},model:{value:(_vm.document.module),callback:function ($$v) {_vm.$set(_vm.document, "module", $$v)},expression:"document.module"}})],1)],1)],1),(_vm.isVisible(['Rental', 'Operational', 'PDV']))?_c('b-tab',{attrs:{"title":"Produtos"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('RadioGroup',{attrs:{"title":"Agrupar","formName":_vm.formName,"field":"group","options":[
            { text: 'Sim', value: true },
            { text: 'Não', value: false } ]},model:{value:(_vm.document.config.product.group),callback:function ($$v) {_vm.$set(_vm.document.config.product, "group", $$v)},expression:"document.config.product.group"}})],1),_c('b-col',{attrs:{"sm":"7"}},[_c('RadioGroup',{attrs:{"title":"Ordenar","formName":_vm.formName,"field":"order","options":[
            { text: 'Padrão', value: 'default' },
            { text: 'Categoria', value: 'category' },
            { text: 'Nome do produto', value: 'product' } ]},model:{value:(_vm.document.config.product.orderBy),callback:function ($$v) {_vm.$set(_vm.document.config.product, "orderBy", $$v)},expression:"document.config.product.orderBy"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('CheckboxGroup',{attrs:{"title":"Mostrar","field":"permission","markFormDirty":false,"options":[
            { text: 'Produtos da locação', value: 'showProduct' },
            { text: 'Produtos somente estoque', value: 'showOnlyStock' },
            { text: 'Acessório(s)', value: 'showAccessory' },
            { text: 'Serviço(s)', value: 'showService' } ],"initialValue":_vm.document.config.product.show},model:{value:(_vm.document.config.product.show),callback:function ($$v) {_vm.$set(_vm.document.config.product, "show", $$v)},expression:"document.config.product.show"}})],1)],1)],1):_vm._e(),(_vm.isVisible(['MovementStock']))?_c('b-tab',{attrs:{"title":"Movimentação"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"7"}},[_c('RadioGroup',{attrs:{"title":"Ordenar","formName":_vm.formName,"field":"order","options":[
            { text: 'Padrão', value: 'default' },
            { text: 'Categoria', value: 'category' },
            { text: 'Nome do produto', value: 'product' } ]},model:{value:(_vm.document.config.moviment.orderBy),callback:function ($$v) {_vm.$set(_vm.document.config.moviment, "orderBy", $$v)},expression:"document.config.moviment.orderBy"}})],1)],1)],1):_vm._e(),_c('b-tab',{attrs:{"title":"Marca d'água"}},[_c('div',{staticClass:"div-alert"},[_c('Alert',{attrs:{"type":"warning"}},[_c('span',[_vm._v(" Atenção, "),_c('b',[_vm._v("NÃO")]),_vm._v(" funciona na opção Imprimir! ")])])],1),_c('FileUpload',{attrs:{"title":"Carregar Imagem","classIcon":"fa-solid fa-file-export","container":"nixloc-watermark","accepted":".png .jpg","allowed":".png .jpg","disabled":true,"urlPost":"/api/v1/adm/file-upload/upload","urlRemove":"/api/v1/adm/file-upload/delete","nameDataBase":_vm.document.imageWatermark},model:{value:(_vm.document.imageWatermark),callback:function ($$v) {_vm.$set(_vm.document, "imageWatermark", $$v)},expression:"document.imageWatermark"}}),_c('br'),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('InputNumber',{attrs:{"title":"Altura (pixel)","field":"imageHeight","formName":_vm.formName},model:{value:(_vm.document.imageHeight),callback:function ($$v) {_vm.$set(_vm.document, "imageHeight", $$v)},expression:"document.imageHeight"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('InputNumber',{attrs:{"title":"Largura (pixel)","field":"imageWidth","formName":_vm.formName},model:{value:(_vm.document.imageWidth),callback:function ($$v) {_vm.$set(_vm.document, "imageWidth", $$v)},expression:"document.imageWidth"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('InputNumber',{attrs:{"title":"Nítidez (%)","field":"opacity","formName":_vm.formName},model:{value:(_vm.document.opacity),callback:function ($$v) {_vm.$set(_vm.document, "opacity", $$v)},expression:"document.opacity"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('RadioGroup',{attrs:{"title":"Alinhamento Vertical","formName":_vm.formName,"field":"order","options":[
            { text: 'Topo', value: 0 },
            { text: 'Centro', value: 1 },
            { text: 'Rodapé', value: 2 } ]},model:{value:(_vm.document.verticalAlignment),callback:function ($$v) {_vm.$set(_vm.document, "verticalAlignment", $$v)},expression:"document.verticalAlignment"}})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('RadioGroup',{attrs:{"title":"Alinhamento Horizontal","formName":_vm.formName,"field":"order","options":[
            { text: 'Esquerda', value: 0 },
            { text: 'Centro', value: 1 },
            { text: 'Direita', value: 2 } ]},model:{value:(_vm.document.horizontalAlignment),callback:function ($$v) {_vm.$set(_vm.document, "horizontalAlignment", $$v)},expression:"document.horizontalAlignment"}})],1)],1)],1),_c('b-tab',{attrs:{"title":"Margem"}},[_c('Alert',{attrs:{"type":"warning"}},[_c('span',[_vm._v(" Atenção, "),_c('b',[_vm._v("NÃO")]),_vm._v(" funciona na opção Imprimir! ")])]),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('InputNumber',{attrs:{"title":"Topo","field":"imageHeight","formName":_vm.formName},model:{value:(_vm.document.marginTop),callback:function ($$v) {_vm.$set(_vm.document, "marginTop", $$v)},expression:"document.marginTop"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('InputNumber',{attrs:{"title":"Esquerda","field":"imageWidth","formName":_vm.formName},model:{value:(_vm.document.marginLeft),callback:function ($$v) {_vm.$set(_vm.document, "marginLeft", $$v)},expression:"document.marginLeft"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('InputNumber',{attrs:{"title":"Direita","field":"imageHeight","formName":_vm.formName},model:{value:(_vm.document.marginRight),callback:function ($$v) {_vm.$set(_vm.document, "marginRight", $$v)},expression:"document.marginRight"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('InputNumber',{attrs:{"title":"Rodapé","field":"imageWidth","formName":_vm.formName},model:{value:(_vm.document.marginBottom),callback:function ($$v) {_vm.$set(_vm.document, "marginBottom", $$v)},expression:"document.marginBottom"}})],1)],1)],1),_c('b-tab',{attrs:{"title":"Outros"}},[_c('InputText',{attrs:{"title":"Style Css","field":"css","formName":_vm.formName,"required":false},model:{value:(_vm.document.styleCss),callback:function ($$v) {_vm.$set(_vm.document, "styleCss", $$v)},expression:"document.styleCss"}}),_c('RadioGroup',{attrs:{"title":"Mostrar Páginas (ex: 1 de 5)","formName":_vm.formName,"field":"order","options":[
        { text: 'Sim', value: true },
        { text: 'Não', value: false } ]},model:{value:(_vm.document.showPages),callback:function ($$v) {_vm.$set(_vm.document, "showPages", $$v)},expression:"document.showPages"}})],1)],1),_c('br'),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"text-right"},[_c('Button',{attrs:{"_key":"create","type":"success","title":"Salvar","disabled":!_vm.isFormValid(_vm.formName),"classIcon":"fas fa-create","size":"medium","clicked":_vm.create}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }